import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"

import {
  fab,
  faHtml5,
  faJs,
  faReact,
  faCss3,
  faGalacticSenate,
} from "@fortawesome/free-brands-svg-icons"

import { faCode } from "@fortawesome/free-solid-svg-icons"

import {
  AboutBanner,
  AboutTextSection,
  TechnologieSection,
  GenereicPara,
  GenericH2,
} from "../styles/IndexStyles"

library.add(fab)

const about = () => {
  return (
    <Layout>
      <section style={{ position: "relative" }}>
        <AboutBanner />
      </section>

      <AboutTextSection>
        <h2
          style={{
            fontFamily: "Inconsolata",
            fontSize: "2rem",
            color: "white",
            paddingLeft: "200px",
            paddingRight: "200px",
            paddingTop: "150px",
            paddingBottom: "40px",
          }}
        >
          Hey, my name is Jule. <br />I am a digital media student with a great
          enthusiasm for web development and new techniques.
        </h2>

        <p
          style={{
            fontFamily: "Inconsolata",
            fontSize: "1.5rem",
            color: "white",
            textAlign: "left",
            paddingLeft: "200px",
            paddingRight: "200px",
            paddingBottom: "150px",
          }}
        >
          At the moment I am very focused on finishing my bachelor thesis.
          Besides my studies, I am a working student for Denkfabrik Digital
          (DDX). Before that, I worked for Boulder Base Bremen and was a tutor
          for media informatics at the University of Bremen.
          <br />
          <Link style={{ color: "#e91150" }} to="http://www.das-huckepack.de/">
            After my A-levels, I lived and worked for a year in South Africa
            with the "Weltwärts" programme in a children's home near
            Johannesburg.
          </Link>
          <br />
          Besides studying and working, I like to educate myself in the latest
          and hottest web development techniques, go running or take care of my
          balcony and houseplants. Fair clothing, art, animal rights are just
          some of my other areas of interest.
        </p>
      </AboutTextSection>

      <TechnologieSection>
        <div className="header__section4">
          <div className="title__section4">My Technologies</div>
        </div>
        <div className="grid__section4">
          <div className="item1" style={{ backgroundColor: "#4e6c86" }}>
            <div className="flex__section4">
              <FontAwesomeIcon
                icon={faReact}
                color="#e91150"
                size="3x"
                fixedWidth
              />
              <GenericH2 none>React</GenericH2>
            </div>
            <GenereicPara lessSize lessSpacing grey>
              Modern JavaScript framework which will make your web application
              extremely fast and, at the same time, handy for every user.
            </GenereicPara>
          </div>
          <div className="item2" style={{ backgroundColor: "#753510" }}>
            <div className="flex__section4">
              <FontAwesomeIcon
                icon={faCode}
                color="#e91150"
                size="3x"
                fixedWidth
              />
              <GenericH2 none>React Native</GenericH2>
            </div>
            <GenereicPara lessSize lessSpacing grey>
              Cross-platform for mobile app development based on Javascript,
              whose resulting code is compiled to Android and iOS.
            </GenereicPara>
          </div>
          <div className="item3" style={{ backgroundColor: "#548498" }}>
            <div className="flex__section4">
              <FontAwesomeIcon
                icon={faJs}
                color="#e91150"
                size="3x"
                fixedWidth
              />
              <GenericH2 none>JavaScript</GenericH2>
            </div>
            <GenereicPara lessSize lessSpacing grey>
              JavaScript is the language of the web. It is used for Web
              development, mobile development and app development and everything
              else.
            </GenereicPara>
          </div>
          <div className="item4" style={{ backgroundColor: "#331e1c" }}>
            <div className="flex__section4">
              <FontAwesomeIcon
                icon={faHtml5}
                color="#e91150"
                size="3x"
                fixedWidth
              />
              <GenericH2 none>HTML5</GenericH2>
            </div>
            <GenereicPara lessSize lessSpacing grey>
              HTML, a standardized system for tagging text files to achieve
              font, colour, graphic, and hyperlink effects on World Wide Web
              pages.
            </GenereicPara>
          </div>
          <div className="item5" style={{ backgroundColor: "#b2671f" }}>
            <div className="flex__section4">
              <FontAwesomeIcon
                icon={faCss3}
                color="#e91150"
                size="3x"
                fixedWidth
              />
              <GenericH2 none>CSS3</GenericH2>
            </div>
            <GenereicPara lessSize lessSpacing grey>
              CSS is a style sheet language used for describing the presentation
              of a document written in a markup language like HTML.
            </GenereicPara>
          </div>
          <div className="item6" style={{ backgroundColor: "#86685f" }}>
            <div className="flex__section4">
              <FontAwesomeIcon
                icon={faGalacticSenate}
                color="#e91150"
                size="3x"
                fixedWidth
              />
              <GenericH2 none>Gatsby</GenericH2>
            </div>
            <GenereicPara lessSize lessSpacing grey>
              Gatsby is a free and open source framework based on React that
              helps developers build blazing fast websites and apps
            </GenereicPara>
          </div>
        </div>
      </TechnologieSection>
    </Layout>
  )
}

export default about
